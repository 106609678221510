@import "vendors/font-awesome.scss";
@import "vendors/icofont.scss";
@import "vendors/themify/_themify-icons.scss";
@import "vendors/flag-icon.scss";
@import "vendors/feather-icon/feather-icon";
@import "vendors/whether-icon.scss";
@import "vendors/scrollbar.scss";
@import "vendors/animate.scss";
@import "vendors/prism.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "style";
@import "responsive";
@import "color-1.scss";
@import "vendors/dropzone.scss";
@import "./vendors/image-cropper.scss";
// @import "vendors/owlcarousel.scss";
// @import "vendors/icofont.scss";
@import "vendors/chartist.scss";

.vld-overlay.is-full-page {
  z-index: 10000 !important;
}

.custom-form-select {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px 10px !important;
  outline: 1px solid #0074ce !important;
}

.primary-text-color {
  color: #0074ce !important;
}

.primary-bg-color {
  background-color: #0074ce !important;
}

.custom-form-control {
  border: 1px solid #0074ce !important;
  border-radius: 14px;
  height: 50px;
  width: 100%;
  padding: 12px 25px !important;
  background-color: #fff !important;
}

.custom-btn {
  border-radius: 14px !important;
  height: 50px;
  font-size: 18px !important;
  border: none;
}

.custom-btn-outline {
  border-radius: 14px !important;
  height: 50px;
  font-size: 18px !important;
  border: 1px solid #0074ce !important;
  line-height: 32px;
}

.custom-btn:disabled {
  background-color: #a0a0a0; /* Disabled background color */
  color: #dcdcdc; /* Disabled text color */
  cursor: not-allowed; /* Disabled cursor style */
}

.custom-form-control:focus {
  outline: 1px solid #0074ce !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
  background-color: var(--bs-btn-hover-bg) !important;
}

/* ChatBox */
.main-bg-blue {
  background-color: rgba(0, 116, 206, 1);
}

.chatbox-1 {
  height: 481px;
  width: 412px;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  right: 130px;
  z-index: 1;
}

.chat-head {
  border-radius: 16px 16px 0 0;
  height: 62px;
}

.blue-chat {
  padding: 14px 13px 14px 16px;
  border-radius: 8px 8px 0 0;
  color: white;
}

.gray-chat {
  padding: 14px 13px 14px 16px;
  border-radius: 8px 8px 0 0;
  background: rgba(231, 231, 231, 1);
  color: #000;
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.chatting-body {
  height: 325px;
  padding: 20px;
}

.chat-texting {
  height: 45px;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 8px;
  font-size: 12px;
  padding-inline: 23px 10px;
}

.chatbox-2 {
  height: 481px;
  width: 412px;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  right: 570px;
  z-index: 1;
}

.chatbox-2 .chatting-body {
  height: 100%;
  padding: 20px;
}

.chat-list-search-icon {
  top: 13px;
  left: 13px;
}

.chat-icon {
  position: fixed;
  bottom: 60px;
  right: 96px;
}

.list-enter-active,
.list-leave-active {
  transition: height 0.5s;
}

.list-enter-from,
.list-leave-to {
  height: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.table-blue tr th {
  background-color: #0074ce;
  color: #fff;
}

.add-btn {
  background-color: #0074ce;
  color: #fff;
  font-size: 18px;
  border-radius: 50%;
  border: none;
  width: 50px;
  height: 50px;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown .dropdown-toggle {
  background-color: transparent !important;
  color: #222 !important;
  width: auto !important;
}

.dropdown .dropdown-toggle::after {
  content: none !important;
}

.dropdown .dropdown-toggle:focus {
  border: none !important;
}

.dropdown-menu {
  z-index: 10 !important;
}

.dropdown-item:hover {
  background-color: #eee !important;
}

.chat-profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.chat-profile-image-small {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

input:focus {
  outline: none !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active span {
  color: #0074ce !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a:hover svg.stroke-icon {
  stroke: #0074ce !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active svg.stroke-icon {
  stroke: #0074ce !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active {
 
  background-color: #e0f1fc !important;
  color: #0074ce !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link:hover {
 
  background-color: #e0f1fc !important;
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span:hover {
  color: #0074ce !important; 
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link::after {
   background-color: #0074ce !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link:hover span,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link:hover i {
  color: #0074ce !important;
}



.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-submenu li a.active {
  color: #0074ce !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-submenu li a:hover {
  color: #0074ce !important; 
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .mega-menu-container .mega-box .link-section .submenu-title h5::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .mega-menu-container .mega-box .link-section .submenu-title .h5::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-submenu li a::before {
 background: #0074ce !important;
}

.input-group-text {
  padding: 0.7rem 0.7rem !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu li a.active span {
  color: #0074ce !important;
}

